.annotations-background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgb(0, 0, 0, 0.9);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.annotations-container {
    position: fixed;
    background-color: #ffffff;
    z-index: 10001;
    text-align: center;
    border: solid 0.1rem #b83e00;
    border-radius: 5px;
    border-top-right-radius: 20px;
    padding: 0.5rem;
    width: 90%;
    margin: auto
}

.close-annotations {
    position: absolute;
    top: 0rem;
    right: 0rem;
    font-size: 1.5rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: solid 0.2rem #b83e00;
    line-height: 2.2rem;
    cursor: pointer;
}

.annotation-list {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 16rem;
}

.AnnotationField {
    width: 100%;
    margin: auto;
    padding: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.AnnotationField input,
.AnnotationField textarea {
    width: 33%;
    max-width: 33%;
    padding: 0.2rem;
    margin-right: 0.5rem;
    height: 1rem;
    line-height: 2rem;
}

.AnnotationField textarea {
    min-height: 1.1rem;
    max-height: 7rem;
    resize: vertical;
}

.add-annotation {
    font-size: 1.3rem;
    line-height: 2.4rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: solid 0.2rem #b83e00;
    cursor: pointer;
}

.annotation-actions {
    display: flex;
    justify-content: space-between;
    width: 2rem;
}

.save-annotation,
.remove-annotation {
    outline: none;
    text-decoration: none;
    border: none;
    background-color: transparent;
    width: 1rem;
    margin-left: -1rem;
    font-size: 1.5rem;
    cursor: pointer;
    margin-right: 0.5rem;
}

.save-annotation {
    color: #25b800;
}

.remove-annotation {
    color: #b83e00;
}

.annotations-header {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    margin-bottom: -1rem;
    line-height: 1rem;
}

.annotations-header p:first-child {
    width: 2.8125rem;
    text-align: center;
    overflow-wrap: break-word;
}

.annotations-header p {
    width: 25%;
}

.old-loading {
    line-height: 1.7rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.1rem;
    border-radius: 50%;
    border: solid 0.2rem #b83e00;
    cursor: pointer;
}
