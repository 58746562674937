@font-face {
    src: url(./components/GothamBook.ttf);
    font-family: "GothamBook";
}
* {
    font-family: "GothamBook";
    /* transition: all 0.5s linear; */
}
.mapContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

body {
    padding: 0;
    margin: 0;
}

.leaflet-retina .leaflet-control-measure .leaflet-control-measure-toggle, .leaflet-retina .leaflet-control-measure .leaflet-control-measure-toggle:hover {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAXUlEQVRYw+2WsQ3AMAzD9Efu92nJlB/cKUg710IbgPRkD+IoSwAA/6EpNJRFMxRqz/hZFr5m3hVRHp9KxRZ0i6BvwTq5dgQIThS8BQGCEwX2wrFXpr307W8LAMC3XEmGTrvp8dAdAAAAAElFTkSuQmCC') !important;
}

.layer-measuredrag {
    cursor: crosshair !important;
}

.leaflet-routing-geocoders div {
    /* padding: 4px 0px 4px 0px; */
    padding: 0 !important
}

.leaflet-routing-geocoders input {
    padding: 0 !important;
    margin: 5px 0;
    border-radius: 2px;
    border: 1px solid black;
}
.leaflet-bottom .leaflet-control-scale {
    margin-bottom: 5px;
    margin-left: 100px !important;
}
.leaflet-routing-container.leaflet-routing-container-hide{
    display: none;
}