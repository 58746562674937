.srbe-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}

.srbe-content {
    background-color: #ffffff;
    width: 15rem;
    height: 15rem;
    border-radius: 12px;
    border: solid 2px #b83e00;
    padding: 10px;
}

.close-button {
    position: absolute;
    top: 0rem;
    right: 0rem;
    font-size: 1.5rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: solid 0.2rem #b83e00;
    line-height: 2.3rem;
    cursor: pointer;
}

.title {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 4rem;
    display: flex;
    flex-wrap: wrap;
    line-height: 1rem;
}

.email {
    margin-bottom: 3rem;
    width: 14.5rem;
    text-align: center;
    line-height: 2rem;
}

.button-wraper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.option-button {
    padding: 8px 20px;
    border: solid 2px #000;
    border-radius: 12px;
    cursor: pointer;
    height: 17px;
    line-height: 0.5rem;
    width: 100px;
    font-size: 1rem;
}

.confirmation {
    background-color: #00aa00;
    color: #ffffff;
}

.confirmation:hover {
    background-color: #00ff00;
}

.negation {
    background-color: #aa2200;
    color: #ffffff;
}

.negation:hover {
    background-color: #ff2200;
}